

































































import { computed, defineComponent, ref, Ref } from '@vue/composition-api';
import core from '@/admin/core';
import { Form, useForm } from '@/admin/form';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';

export default defineComponent({
  name: 'Forms',
  components: {
    FcRoleLoading,
    FcRoleDeny,
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('forms'));

    const { getForms } = useForm();

    const headers = [
      { text: 'タイトル', value: 'formName' },
      { text: '開始日', value: 'entryStartDate.date' },
      { text: '終了日', value: 'entryEndDate.date' },
      { text: '最新投稿日', value: 'latestEntryDate.date' },
      { text: '', value: 'actions', sortable: false, align: 'end' },
    ];
    const items: Ref<Form[]> = ref([]);
    const isPermitted = core.isPermitted;
    const isLoading = ref(true);
    const hostingUrl = core.hostingUrl;

    getForms().then((forms) => {
      items.value = forms;
      isLoading.value = false;
    });

    return {
      pageTitle: 'フォーム',
      myRoleSettings,
      headers,
      items,
      isLoading,
      isPermitted,
      hostingUrl,
    };
  },
});
